import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FolderTree from './FolderTree';
import FileView from './FileView';
import { useAuth } from '../../../App/AuthContext';
import { useGlobalContext } from '../../../App/GlobalContext';
import { useCommandApi } from '../../../Apis';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import Tooltip from '@material-ui/core/Tooltip';
import SubsetView from './SubsetView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { Dataset } from '../../../interfaces/dataset';

const useStyle = makeStyles((theme) => ({
    greyBadge: {
        backgroundColor: 'grey',
        color: 'white',
    },
    blueBadge: {
        backgroundColor: 'blue',
        color: 'white',
    },
}));

interface DatasetDataProps {
    dataset: Dataset;
}

function DatasetData({ dataset }: DatasetDataProps) {
    const classes = useStyle();

    const { identity, authSession, logout } = useAuth();
    const { config } = useGlobalContext();

    const commandApi = useCommandApi(config, authSession);

    const [selectedPath, setSelectedPath] = useState('');
    const [deletedFolderPath, setDeletedFolderPath] = useState('');
    const [showSubsets, setShowSubsets] = useState(false);
    const [showData, setShowData] = useState(true);
    const [navValue, setNavValue] = useState(1);

    function handleOnSelectPath(path: string) {
        setSelectedPath(path);
    }

    const handleNavChange = async (event: any, newValue: number) => {
        setNavValue(newValue);
        switch (newValue) {
            case 0:
                setShowSubsets(true);
                setShowData(false);
                break;
            case 1:
                setShowSubsets(false);
                setShowData(true);
                break;
        }
    };

    const handleDeleteFolder = (deletedFolderPath: string | null) => {
        setDeletedFolderPath(deletedFolderPath as string);
    };

    const handleChangeTab = (newTabValue: number) => {
        handleNavChange(null, newTabValue);
    };

    const renderContentView = () => {
        if (showData) {
            return (
                <React.Fragment>
                    <Grid item xs={3}>
                        <FolderTree
                            dataset={dataset}
                            selectedPath={selectedPath}
                            onSelect={handleOnSelectPath}
                            deletedFolderPath={deletedFolderPath}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        {selectedPath ? (
                            <FileView
                                dataset={dataset}
                                selectedPath={selectedPath}
                                onSelect={handleOnSelectPath}
                                onChangeTab={handleChangeTab}
                                onDeleteFolder={handleDeleteFolder}
                            />
                        ) : null}
                    </Grid>
                </React.Fragment>
            );
        } else if (showSubsets) {
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        <SubsetView datasetName={dataset.name}></SubsetView>
                    </Grid>
                </React.Fragment>
            );
        }
    };

    // @ts-ignore
    const rootCls = classes.root;

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <Paper square className={rootCls}>
                    <Tabs
                        value={navValue}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="data navigation"
                        onChange={handleNavChange}
                    >
                        <Tooltip title={`Show all subsets`}>
                            <Tab icon={<FolderSpecialIcon />} label="Subsets" />
                        </Tooltip>
                        <Tooltip title="Show files and folders in dataset">
                            <Tab
                                icon={<AccountTreeIcon />}
                                label="Files and folders"
                            />
                        </Tooltip>
                    </Tabs>
                </Paper>
            </Grid>
            <Grid container spacing={3}>
                {renderContentView()}
            </Grid>
        </Grid>
    );
}

export default DatasetData;
